/**
 * External dependencies.
 */
import React, {useState} from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';

/**
 * Internal dependencies.
 */
import WidgetCircle from 'components/widget-circle/widget-circle';
import { getProfileData, registerUser, requireUserID, AskForFile, getAppMetadataValue } from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 0 42px',
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalField: {
		marginBottom: '21px',
		'& input': {
			fontSize: '18px',
			fontWeight: '500',
			letterSpacing: '0.5px',
			height: '44px',
			border: 'none',
			background: '#F0F0F3',
			fontFamily: 'Poppins, sans-serif',
			'&::-webkit-input-placeholder': {
				color: '#858585',
				oapcity: '1',
				fontWeight: '500'
			},
			'&::-moz-placeholder': {
				color: '#858585',
				oapcity: '1',
				fontWeight: '500'
			},
			'&:-moz-placeholder': {
				color: '#858585',
				oapcity: '1',
				fontWeight: '500'
			},
			'&:-ms-input-placeholder': {
				color: '#858585',
				oapcity: '1',
				fontWeight: '500'
			},
		}
	},
	modalCircle: {
		width: '200px',
		height: '200px',
		borderWidth: '2px',
		margin: '0 auto 29px',
		[theme.breakpoints.down('xs')]: {
			margin: '0 auto 29px'
		},
		'& .MuiButtonBase-root': {
			minWidth: '134px',
			fontSize: '11px',
			borderRadius: '9px',
			padding: '8px 10px',
			marginTop: '3px',
		}
	},
	modalActions: {
		margin: '27px -30px 0',
		padding: '25px 24px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			display: 'block',

		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}
	},
	modalButtonDisabled: {
		opacity: '.3'
	},
	modalText: {
		fontSize: '17px',
		fontWeight: '400',
		lineHeight: '1.41',
		padding: '3px 12px 0',
		'& span': {
			fontWeight: '600',
		}
	},
	errorMessage: {
		color: '#f50057'
	}
}));

async function submitCredentials(e, setErrorMessage, setDisabled) {
	e.preventDefault()
	setErrorMessage("");
	setDisabled(true);

	const creatorUserID = await requireUserID(true);
	const creatorProfile = await getProfileData(creatorUserID);
	const metadata = getAppMetadataValue(creatorProfile, "Creator");

	if (!metadata) {
		setErrorMessage(`Tu cuenta de usuario no tiene el metadato "Creator" necesario. Normalmente esto sucede con cuentas creadas manualmente fuera de Circles. Contacta con el equipo de La Frontera VR para arreglarlo.`)
		throw new Error(`"Creator" metadata missing from currently logged-in user's profile.`);
	}

	try {
		await registerUser(document.getElementById("email-field").value, document.getElementById("name-field").value, document.getElementById("password-field").value, metadata, false);
	} catch (error) {
		if (error.message == "The user already exists.")
			setErrorMessage("The email provided is in use already.");
		else
			setErrorMessage(error.message);
			setDisabled(false);
		return;
	}

	alert("Usuario cliente registrado con éxito")
	window.location.reload();
}

const ModalNewUser = ({ onClose, modifier }) => {
	const classes = useStyles();
	const [ errorMessage, setErrorMessage ] = useState("");
	const [ disabled, setDisabled ] = useState(false);

	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} onClick={onClose}>
					<i className="ico-x"></i>
				</button>

				<Typography
					variant="h4"
					component="h4"
					className={classes.modalTitle}
				>Nuevo usuario</Typography>
			</Box>

			<Box className={classes.modalBody}>
				{/* <WidgetCircle
					modifier={classes.modalCircle}
					buttonText="Crear avatar"
					url="#"
				>
					<input type="file" hidden id='fileinput' accept='.png, .jpg' */} 
					{/* 
					onChange={async event => {
						const selectedFile = AskForFile(event, true);

						if (selectedFile) {
							const authToken = await requireAuthToken(false, true);

							const responsePresignedURL = await fetch(`${process.env.REACT_APP_ENDPOINT}/avatar`, {

								// Adding method type
								method: "PUT",

								// Adding headers to the request
								headers: {
									"Content-type": "application/json; charset=UTF-8",
									"Authorization": `Bearer ${authToken}`
								}
							});

							if (!responsePresignedURL.ok) {
								throw new Error(`HTTP error! status: ${responsePresignedURL.status}`); // handle errors
							}

							const data = await responsePresignedURL.json();

							console.log(data)

							if ("Error" in data) {
								throw new Error(`Error getting presigned URL: ${data.Error.Message}`); // handle errors
							}

							if (!data.UploadUrl) {
								throw new Error(`Error getting presigned URL: no upload URL`); // handle errors
							}

							let headersList = {*/
								//"Accept": "*/*",
							/*	"User-Agent": "Thunder Client (https://www.thunderclient.com)",
								"Origin": "https://fake.com" //TODO Change this to our URL
							}

							const responseUpload = await fetch(data.UploadUrl, {
								method: "PUT",
								body: selectedFile,
								headers: headersList
							})

							if (!responseUpload.ok) {
								throw new Error(`HTTP error PUT to avatar presigned url! status: ${responseUpload.status}`); // handle errors
							}

							console.log("All good");

							const responseGenerateAvatar = await fetch(`${process.env.REACT_APP_ENDPOINT}/avatar`, {

								// Adding method type
								method: "POST",

								// Adding headers to the request
								headers: {
									"Accept": "application/json; charset=UTF-8",
									"Authorization": `Bearer ${authToken}`
								}
							});

							if (!responseGenerateAvatar.ok) {
								throw new Error(`HTTP error generating avatar! status: ${responseUpload.status}`); // handle errors
							}

							window.location.reload();
						}
					}}
				*/} 
					{/* />

				</WidgetCircle>
 */}
				<Input placeholder="Nombre de usuario" className={classes.modalField} id="name-field" disabled={disabled} onKeyDown={e => {
							if (e.keyCode == 13 /*enter*/)
								document.getElementById("email-field").focus();
						}} />

				<Input placeholder="Correo electrónico" className={classes.modalField} disabled={disabled} id="email-field" onKeyDown={e => {
							if (e.keyCode == 13 /*enter*/)
								document.getElementById("password-field").focus();
						}} />

				<Input placeholder="Contraseña" className={classes.modalField} disabled={disabled} id="password-field" onKeyDown={(e) => {
							if (e.keyCode == 13 /*enter*/)
							submitCredentials(e, setErrorMessage)
						}}/>

				<Typography
					variant="h4"
					component="h4"
					className={classes.modalText}
				>
					{/* El usuario recibirá un correco electrónico con el <span>código</span> que debe introducir en la aplicación. */}
				</Typography>

				<Typography variant="subtitle1" className={classes.errorMessage}>{errorMessage}</Typography>
			</Box>

			<Box className={classes.modalActions}>
				<Button
					variant="outlined"
					color="secondary"
					className={classes.modalButton}
					onClick={onClose}
				>Cancelar</Button>

				<Button
					variant="contained"
					color="secondary"
					className={classes.modalButton /* + ' ' + classes.modalButtonDisabled */}
					disabled={disabled}
					onClick={(e) => {
						submitCredentials(e, setErrorMessage, setDisabled)
					}}
				>Crear usuario{/* Enviar invitación */}</Button>
			</Box>
		</Box>
	);
};

export default ModalNewUser;