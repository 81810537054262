/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@mui/material/Skeleton';
import LinearProgress from '@mui/material/LinearProgress';
import "@google/model-viewer";

const useStyles = makeStyles(theme => createStyles({
	box: {
		position: 'relative',
		width: '200px',
		height: '200px',
		borderRadius: '50%',
		[theme.breakpoints.down('xs')]: {
			width: '200px',
			height: '200px',
		},
		[theme.breakpoints.down('xs')]: {
			width: '214px',
			height: '214px',
			marginLeft: 'auto',
			marginRight: 'auto',
		}
	},
	boxImage: {
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		borderRadius: '50%',
	},
	boxButton: {
		position: 'absolute',
		bottom: '1px',
		right: '23px',
		width: '36px',
		height: '36px',
		background: '#E6E9EF',
		borderRadius: '50%',
		padding: '0'
	},	
	modelViewerMiClase: {
		height: "100%"
	}

}));

/** 
 * Used for uploading avatar, poster and logo
 */
const WidgetEdit = ( { modifier, image, avatar, onChange, accept, loading } ) => {
	const classes = useStyles();

	if (!image && !avatar) {
		return (
			<Box className={classes.box + ' ' + modifier}>	
				<Skeleton animation='wave' className={classes.boxImage} variant="circular" height={200} />
				
				<IconButton aria-label="edit" component="label" htmlFor={image} className={classes.boxButton}>
					<i className="ico-edit"></i>
					<input type="file" id={image} hidden accept={accept} onChange={onChange ?? ""}/>
				</IconButton>
			</Box>
		);
	}

	return (
		<Box className={classes.box + ' ' + modifier}>
			<style>
				{`#myModelViewer {
					width: 100% !important;
					height: 100% !important;
					border-radius: 50% !important;
					border: 1px solid lightgray !important;
				}`}
			</style>
			
			{avatar ?
			<div className={classes.modelViewerMiClase}>
				<model-viewer id="myModelViewer"
				alt="Your avatar"
				style={{width: "100% !important",
				height: "100% !important",
				borderRadius: "50% !important",
				border: "2px solid lightgray !important"}}
				src={avatar} ar ar-modes="webxr scene-viewer quick-look"
				poster={image}
				seamless-poster shadow-intensity="1" camera-controls enable-pan></model-viewer></div>
					
			:
				<img src={image} alt="" className={classes.boxImage} />
			}
			{loading && <LinearProgress />}
			<IconButton aria-label="edit" component="label" htmlFor={image} className={classes.boxButton}>
				<i className="ico-edit"></i>
				<input type="file" id={image} hidden accept={accept} onChange={onChange ?? ""}/>
			</IconButton>
		</Box>
	);
};

export default WidgetEdit;